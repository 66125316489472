<script setup>
const props = defineProps({
  data: { type: Object, required: true },
  size: { size: String, default: 'large' },
})
const card = props.data;
const classObject = reactive([props.size])


</script>

<template>
  <div class="value-proposition-card card" :class="classObject">
    <component :is='card.icon'></component>
    <div class="content">
      <h3>{{ card.title }}</h3>
      <p>{{ card.description }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">

.value-proposition-card {
  
  padding: 2rem 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color3);
  border-radius: var(--radius);
  
  h3 {
    margin: 1rem 0 .4rem 0;
    text-align: center;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.6rem;
    color: var(--color1);
    text-align: center;
  }
}

.small {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  svg {
    height: 3rem;
    margin-right: 1rem;
  }
  
  .content {
    p {
      text-align: left;
    }
  }
}
</style>
