<script setup>
const props = defineProps({
  variant: { type: String, default: 'default' },
  size: { size: String, default: 'medium' },
  color: { size: String, default: 'light' },
})

const isActive = ref(true);
const buttonClass = 'button-' + props.variant;
const classObject = reactive([buttonClass, props.size, props.color, { active: isActive }] )

</script>

<template>
  <button :class="classObject" >
    <slot>Button</slot>
  </button>
</template>

<style scoped lang="scss">

/* Default */
.button {
  all: unset;
  cursor: pointer;
  border-radius: 6rem;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family:'Be Vietnam Pro', sans-serif;
}

.button-default {
  @extend .button;
  background: var(--color4);
  color:var(--color1);
  border: var(--color1) .1rem solid;
  
  &:hover {
    background: var(--color1);
    color: var(--color4);
  }
}

/* Variants */
.button-primary {
  @extend .button;
  background: var(--color4);
  color:var(--color1);
  border: var(--color1) .1rem solid;
  
  &:hover {
    background: var(--color1);
    color: var(--color4);
  }
}

.button-solid {
  @extend .button;
  background: var(--color4);
  color:var(--color1);
  border: none;
  
  &:hover {
    background: var(--color1);
    color: var(--color4);
  }
}

.button-outline {
  @extend .button;
  background: none;
  color:var(--color1);
  border: var(--color1) .1rem solid;
  
  &:hover {
    background: var(--color1);
    color: var(--color4);
  }
}


/* Sizes */
.small {
  width: 16rem;
  height: 3rem;
  font-size: 1.2rem;
  font-weight: 700;
}

.medium {
  width: 16rem;
  height: 4rem;
  font-size: 1.2rem;
  font-weight: 700;
}

.large {
  width: 16rem;
  height: 4.8rem;
  font-size: 1.3rem;
  font-weight: 700;
}

/* Colors */
.dark {
  background: var(--color1);
  color: var(--color4);
  &:hover {
    background: var(--color2);
    color: var(--color1);
  }
}


</style>
